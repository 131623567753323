import { CanActivateFn, Router } from '@angular/router';
import { AccountService } from '../state';
import { inject } from '@angular/core';

export const MembersGuard: CanActivateFn = () => {
  const accountSvc = inject(AccountService);
  const router = inject(Router);
  const isGPSNRSecretariat = accountSvc.isGPSNRSecretariat();

  if (!isGPSNRSecretariat) {
    router.navigate(['/dashboard']);
  }

  return isGPSNRSecretariat;
};
