<div class="layout-container">
  <!-- PrimeNG toolbar to replace mat-toolbar -->
  <p-toolbar *ngIf="profile?.email" class="nav-toolbar">
    <div class="p-flex p-gap-4 p-align-items-center">
      <div class="p-flex p-align-items-center p-gap-3">
        <button class="nav-button" pButton icon="pi pi-align-justify" (click)="toggleSideNav()"></button>
        <img src="../../assets/images/gpsnr.png" class="logo" alt="logo">
      </div>
      <div class="tab-container">
        <a class="tab-menu" routerLink="dashboard" routerLinkActive="active">RR Form Dashboard</a>
        <a class="tab-menu" routerLink="suppliers" routerLinkActive="active" *ngIf="isGPSNRMember() && !isGPSNRSecretariat()">Supplier List</a>
        <a class="tab-menu" routerLink="members" routerLinkActive="active" *ngIf="isGPSNRSecretariat()">Member List</a>
      </div>
    </div>
    <div class="ui-toolbar-group-right">
      <p-menu #menu [popup]="true" [model]="items" class="nav-menu" [styleClass]="'nav-menu-overlay'">
        <ng-template pTemplate="item" let-item>
          <div class="menu-item" *ngIf="!item.class">
            <span> {{ item.label }}</span>
            <i class="pi pi-check text-primary" *ngIf="item.id === (userInfo$ | async).orgId"></i>
          </div>
          <div [className]="item.class" class="company-class" *ngIf="item.class">
            <span> {{ item.label }}</span>
            <i class="pi pi-check text-primary" *ngIf="item.id === (userInfo$ | async).orgId"></i>
          </div>
        </ng-template>
      </p-menu>
      <button type="button" class="profile-button" pButton (click)="menu.toggle($event)" [disabled]="items.length === 0">
        <img [src]="profile.picture" width="40" height="40" class="rounded-circle" alt="profile-picture">
      </button>
    </div>
  </p-toolbar>

  <div class="content-container">
    <p-sidebar class="rr-sidebar" [(visible)]="sidebarVisible" [baseZIndex]="10000" [position]="'left'">
      <ng-template pTemplate="headless">
        <div class="p-flex p-flex-column p-h-full">
          <div class="p-overflow-y-auto">
            <p-button class="close-btn-sidebar" (onClick)="toggleSideNav()" type="button"
              icon="pi pi-chevron-circle-left"></p-button>
            <ul class="list-none p-0 m-0">
              <li tabindex="0" routerLink="dashboard/my-submissions" routerLinkActive="active"
                class="nav-item p-flex p-align-items-center p-cursor-pointer p-p-3 p-text-700 p-no-underline">
                <span class="font-medium">My Submission</span>
              </li>
              <li tabindex="0" *ngIf="isGPSNRSecretariat()" routerLink="dashboard/secretariat" routerLinkActive="active"
                class="nav-item p-flex p-align-items-center p-cursor-pointer p-p-3 p-text-700 p-no-underline">
                <span class="font-medium">RR Submissions</span>
              </li>
              <li tabindex="0" *ngIf="isGPSNRMember() && !isGPSNRSecretariat()" routerLink="dashboard/suppliers-submissions" routerLinkActive="active"
                class="nav-item p-flex p-align-items-center p-cursor-pointer p-p-3 p-text-700 p-transition-duration-150 p-transition-colors p-p-ripple p-no-underline">
                <span class="font-medium">Supplier Submissions</span>
              </li> 
              <li tabindex="0" (click)="openNewWindow()"
                class="nav-item p-flex p-align-items-center p-cursor-pointer p-p-3 p-text-700 p-transition-duration-150 p-transition-colors p-p-ripple p-no-underline">
                <span class="font-medium">FAQs</span>
                <i class="pi pi-external-link p-ml-auto"></i>
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </p-sidebar>

    <div class="main-content">
      <router-outlet class="router-outlet"></router-outlet>
    </div>
  </div>

</div>