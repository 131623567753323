import { Injectable } from '@angular/core';
import { SubmissionStore } from '@modules/dashboard/state/submission.store';
import { AuthStore } from "../auth/auth.store";
import { MemberStore } from '@modules/members/state/member.store';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private authStore: AuthStore,
    private submissionStore: SubmissionStore,
    private memberStore: MemberStore
  ) {
  }

  resetAll() {
    this.authStore.reset();
    this.submissionStore.reset();
    this.memberStore.destroy();
  }
}

