import { EntityStore,  persistState,  StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { initialState, MemberState } from "./member.model";

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'members', idKey: 'orgId', resettable: true })
export class MemberStore extends EntityStore<MemberState> {
  constructor() {
    super(initialState);
  }
}

export const memberPersistStorage = persistState({
  include: ['members'],
  key: 'membersStore',
});
