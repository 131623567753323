export enum FormType {
  selectboxes = 'selectboxes',
  radio = 'radio',
  select = 'select',
  datagrid = 'datagrid'
}

export const PDF_TEMPLATE_STYLE_CLASS = `
          /* Styles for pdf preview template section */
          .pdf-pdf-preview-section {
              page-break-inside: avoid;
          }

          .pdf-header {
            font-weight: bold;
            background: #f4f4f4;
          }

          /* Styles for the question content */
          .pdf-form-question {
              margin-top: 2px;
              margin-bottom: 2px;
              padding: 4px;
          }

          /* Styles for the response container */
          .pdf-response-container {
              margin-top: 4px;
              margin-bottom: 4px;
              padding: 4px;
          }

          /* Styles for the response label */
          .pdf-response-label {
              font-weight: bold;
              margin-bottom: 2px;
              opacity: 0.6;
          }

          /* Styles for each item in selectboxes */
          .pdf-response-item {
              display: flex;
              gap: 2px;
          }

          /* Bold label in response */
          .pdf-response-label-bold {
              font-weight: bold;
          }

          /* Styles for table */
          .pdf-table {
              width: 100%;
              overflow-x: auto;
              display: block;
          }

          /* Styles for table row */
          .pdf-table-row {
              display: flex;
              flex-wrap: wrap;
          }

          /* Styles for table header */
          .pdf-table-row.header {
              background-color: #f4f4f4;
              font-weight: bold;
              border: 1px solid black;
          }

          /* Styles for table cells */
          .pdf-table-cell {
              flex: 1;
              padding: 8px;
              min-width: 120px;
              text-align: left;
              border: 1px solid black;
              word-wrap: break-word;
          }

          /* Default response styling */
          .pdf-default-response {
              word-wrap: break-word;
          }

                `;
