import { Component } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss'],
  standalone: true,
  imports: [
    ButtonModule
  ]
})

export class PrivacyNoticeComponent {
  constructor(public ref: DynamicDialogRef) { }

  onAccept(): void {
    this.ref.close(true);
  }

  onDecline(): void {
    this.ref.close(false);
  }
}

