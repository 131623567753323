import { Component, Injectable } from '@angular/core';
import { FormType } from '@modules/forms/constants/form.constant';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {

  hideErrorToast = false;

  constructor(private messageService: MessageService) {}

  showSuccessToast(message: string) {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: message,
    });
  }

  showErrorToast(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }

  processPreview(components: any, submissionData: any): any {
    let qnNumber = '';
    const previewQuestions = [];
    for (let i = 0; i < components.length; i++) {
      const page = components[i] as any;
      
      if (page.type === 'components') {
        previewQuestions.push(...this.processPreview(page.components, submissionData));
      }

      if (page.key.includes('_hidden')) {
        continue;
      }

      if (page.type === 'htmlelement') {
        const h3Match = page.content.match(/<h3>(.*?)<\/h3>/);
        if (h3Match) {
          qnNumber = h3Match[1];
        }
        const element = {
          content: page.content ,
          value: page.label === 'HTML' ? null : page.label,
          type: page.type,
          isMainQuestion: page.content.includes('<h3>'),
          questionNumber: qnNumber
        }

        previewQuestions.push(element);
      } else {

        if (submissionData?.[page.key] != null) {

          const getPageValues = (page: any) => {
            if (page.type === FormType.select) {
              return page?.component?.data?.values;
            }
            return page?.component?.values;
          }

          const element = {
            content: page.label,
            type: page.type,
            value: submissionData?.[page.key],
            pageValues: getPageValues(page),
            pageColumns: [],
            questionNumber: qnNumber
          };

          if (page.type === 'datagrid') {
            const flattenedColumns = this.getColumns(page.columns);
            element.pageColumns = [...page.columns.filter((p: any) => !p.rows || !p.rows.length), ...flattenedColumns] as any;
          }

          previewQuestions.push(element);
        }
      }
    }

    return previewQuestions;
  }

  getColumns(columns: any[]): any[] {
    const newColumns: any[] = [];
    for (const col of columns) {
      if (col.rows && col.rows.length) {
        this.getAllColumnRowsComponent(col.rows, newColumns);
      }
    }
    return newColumns;
  }

  getAllColumnRowsComponent(rows: any[], components: Component[]): void {
    for (const row of rows) {
      if (Array.isArray(row)) {
        this.getAllColumnRowsComponent(row, components);
      } else {
        components.push(...row.components);
      }

    }
  }
}
