import { EntityState } from "@datorama/akita";
import { Member } from "../models/member.model";

export interface MemberState extends EntityState<Member, string> {
    isFirstLoad: boolean;
    totalMemberRecords: number;
    refreshMembers: boolean | null;
}

export const initialState: MemberState = {
    isFirstLoad: true,
    totalMemberRecords: 0,
    refreshMembers: null
}
